<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3.76673 4.94556L1.16106 2.33989L2.33957 1.16138L18.8388 17.6605L17.6603 18.8391L14.9019 16.0808C13.4842 16.9797 11.8028 17.5003 9.99993 17.5003C5.50644 17.5003 1.76813 14.2671 0.984375 10.0003C1.34827 8.0191 2.34911 6.26084 3.76673 4.94556ZM12.2978 13.4766L11.0778 12.2566C10.7513 12.4128 10.3858 12.5003 9.99993 12.5003C8.61918 12.5003 7.49989 11.3809 7.49989 10.0003C7.49989 9.61425 7.58736 9.24875 7.74353 8.92234L6.52356 7.70239C6.08729 8.36109 5.83322 9.151 5.83322 10.0003C5.83322 12.3014 7.69871 14.1669 9.99993 14.1669C10.8491 14.1669 11.639 13.9128 12.2978 13.4766ZM6.64506 3.13354C7.68407 2.72474 8.81576 2.50022 9.99993 2.50022C14.4933 2.50022 18.2317 5.73335 19.0154 10.0003C18.7553 11.4166 18.1695 12.7191 17.3387 13.8272L14.1222 10.6107C14.1514 10.4114 14.1666 10.2076 14.1666 10.0003C14.1666 7.69903 12.3011 5.83355 9.99993 5.83355C9.79251 5.83355 9.58868 5.84869 9.38943 5.87794L6.64506 3.13354Z"
            fill="#757575"
        />
    </svg>
</template>
